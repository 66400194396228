import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

interface LogotypeProps {}

const Logotype: React.FC<LogotypeProps> = ({}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="logo"
      width="196"
      height="32"
      viewBox="0 0 196 32"
      fill="none"
    >
      <path
        d="M13.4303 10.4172V-6.10352e-05H16.6303V10.4172C16.6303 11.6533 17.9723 12.4225 19.039 11.7978L28.2217 6.41933L29.839 9.18055L20.6563 14.559C17.4564 16.4332 13.4303 14.1255 13.4303 10.4172ZM1.7765 6.50938L11.0257 11.7726C14.2488 13.6067 14.2633 18.2472 11.0517 20.1014L2.03017 25.31L0.430173 22.5387L9.45175 17.3301C10.5223 16.712 10.5174 15.1652 9.44307 14.5538L0.193848 9.29061L1.7765 6.50938ZM19.039 20.2021C17.9723 19.5774 16.6303 20.3466 16.6303 21.5827V31.9999H13.4303V21.5827C13.4303 17.8743 17.4564 15.5667 20.6563 17.4409L29.839 22.8193L28.2217 25.5806L19.039 20.2021Z"
        fill="#1C1A22"
      />
      <path
        d="M92.5312 13.9055V0.352661H95.4742V5.85126H103.141V0.352661H106.065V13.9055H103.141V8.05845H95.4742V13.9055H92.5312Z"
        fill="#1C1A22"
      />
      <path
        d="M83.8549 14.1952C79.3824 14.1952 76.1685 11.5621 76.1685 7.12833C76.1685 2.69459 79.3824 0.0614624 83.8549 0.0614624C88.308 0.0614624 91.5026 2.69459 91.5026 7.12833C91.5026 11.5621 88.308 14.1952 83.8549 14.1952ZM83.8549 11.9493C86.5848 11.9493 88.4822 10.168 88.4822 7.12833C88.4822 4.08861 86.5848 2.30737 83.8549 2.30737C81.1056 2.30737 79.1888 4.08861 79.1888 7.12833C79.1888 10.168 81.1056 11.9493 83.8549 11.9493Z"
        fill="#1C1A22"
      />
      <path
        d="M75.4015 6.02473H72.4586C72.0907 3.66266 70.3095 2.30737 67.9861 2.30737C65.3143 2.30737 63.4749 4.06925 63.4749 7.12833C63.4749 10.2068 65.3143 11.9493 67.9861 11.9493C70.3482 11.9493 72.1294 10.5359 72.4586 8.11575H75.4015C74.9562 11.9106 71.9358 14.1952 67.928 14.1952C63.533 14.1952 60.4546 11.5621 60.4546 7.12833C60.4546 2.71396 63.5524 0.0614624 67.9474 0.0614624C71.9165 0.0614624 74.9562 2.28801 75.4015 6.02473Z"
        fill="#1C1A22"
      />
      <path
        d="M56.5322 13.9055V0.352661H59.4558V13.9055H56.5322Z"
        fill="#1C1A22"
      />
      <path
        d="M42.1035 13.9055V0.352661H50.6418C53.6235 0.352661 55.4628 1.74667 55.4628 4.12811C55.4628 5.67701 54.669 6.85805 53.2556 7.41952C54.4947 7.78739 54.9788 8.65865 55.0368 10.0527C55.0949 11.7177 55.2111 12.9762 55.637 13.9055H52.5973C52.2488 13.1698 52.1907 12.1824 52.1327 10.1688C52.1133 8.94906 51.5518 8.50375 50.2933 8.50375H45.0464V13.9055H42.1035ZM50.3901 2.55985H45.0464V6.33529H50.3901C51.7261 6.33529 52.5586 5.65765 52.5586 4.43789C52.5586 3.21813 51.7261 2.55985 50.3901 2.55985Z"
        fill="#1C1A22"
      />
      <path
        d="M189.358 14.1756C185.118 14.1756 182.543 12.3944 182.021 9.18042H185.002C185.389 11.0972 186.783 12.0265 189.242 12.0265C191.701 12.0265 192.863 11.2908 192.863 10.2647C192.863 8.90936 190.946 8.58022 188.72 8.23172C185.719 7.76705 182.175 7.26366 182.175 4.16585C182.175 1.72634 184.537 0.0806274 188.39 0.0806274C192.495 0.0806274 195.186 1.86186 195.67 4.97903H192.843C192.456 3.21715 190.869 2.22973 188.468 2.22973C186.183 2.22973 185.138 2.96546 185.138 3.91416C185.138 5.30817 187.325 5.59859 189.726 5.98581C192.611 6.45048 195.806 7.07005 195.806 10.013C195.806 12.4912 193.424 14.1756 189.358 14.1756Z"
        fill="#1C1A22"
      />
      <path
        d="M169.678 13.9055V0.352661H181.682V2.57921H172.621V5.88998H179.358V8.05845H172.621V11.7177H181.682V13.9055H169.678Z"
        fill="#1C1A22"
      />
      <path
        d="M168.737 6.02473H165.795C165.427 3.66266 163.645 2.30737 161.322 2.30737C158.65 2.30737 156.811 4.06925 156.811 7.12833C156.811 10.2068 158.65 11.9493 161.322 11.9493C163.684 11.9493 165.465 10.5359 165.795 8.11575H168.737C168.292 11.9106 165.272 14.1952 161.264 14.1952C156.869 14.1952 153.791 11.5621 153.791 7.12833C153.791 2.71396 156.888 0.0614624 161.283 0.0614624C165.252 0.0614624 168.292 2.28801 168.737 6.02473Z"
        fill="#1C1A22"
      />
      <path
        d="M138.744 13.9055L145.037 0.352661H148.425L154.737 13.9055H151.445L150.109 10.8465H143.159L141.823 13.9055H138.744ZM144.243 8.38759L144.088 8.75545H149.18L149.025 8.38759L146.644 2.8309L144.243 8.38759Z"
        fill="#1C1A22"
      />
      <path
        d="M127.814 13.9055V0.352661H136.023C139.179 0.352661 141.135 1.94029 141.135 4.59278C141.135 7.20655 139.179 8.81354 136.062 8.81354H130.757V13.9055H127.814ZM135.81 2.55985H130.757V6.64507H135.81C137.34 6.64507 138.211 5.94807 138.211 4.61214C138.211 3.29557 137.34 2.55985 135.81 2.55985Z"
        fill="#1C1A22"
      />
      <path
        d="M120.324 14.1756C116.084 14.1756 113.509 12.3944 112.986 9.18042H115.968C116.355 11.0972 117.749 12.0265 120.208 12.0265C122.667 12.0265 123.829 11.2908 123.829 10.2647C123.829 8.90936 121.912 8.58022 119.685 8.23172C116.684 7.76705 113.141 7.26366 113.141 4.16585C113.141 1.72634 115.503 0.0806274 119.356 0.0806274C123.461 0.0806274 126.152 1.86186 126.636 4.97903H123.809C123.422 3.21715 121.834 2.22973 119.434 2.22973C117.149 2.22973 116.103 2.96546 116.103 3.91416C116.103 5.30817 118.291 5.59859 120.692 5.98581C123.577 6.45048 126.772 7.07005 126.772 10.013C126.772 12.4912 124.39 14.1756 120.324 14.1756Z"
        fill="#1C1A22"
      />
      <path
        d="M130.945 31.6662V26.671L124.556 18.1133H126.899L131.991 24.9865L137.064 18.1133H139.309L132.901 26.7097V31.6662H130.945Z"
        fill="#B156EC"
      />
      <path
        d="M109.074 31.6662V18.1133H111.978L114.282 23.7861L116.567 29.5752L118.871 23.7861L121.156 18.1133H124.04V31.6662H122.162L122.182 24.9284L122.201 19.8752L120.226 24.9284L117.516 31.6662H115.56L112.85 24.9284L110.855 19.8558L110.875 24.9284V31.6662H109.074Z"
        fill="#B156EC"
      />
      <path
        d="M96.8984 31.6662V18.1133H107.992V19.6428H98.8539V23.9604H105.437V25.4706H98.8539V30.156H107.992V31.6662H96.8984Z"
        fill="#B156EC"
      />
      <path
        d="M82.8457 31.6662V18.1133H88.4992C92.8748 18.1133 95.8565 20.6109 95.8565 24.9091C95.8565 29.1492 92.8555 31.6662 88.4798 31.6662H82.8457ZM88.4217 19.6428H84.8012V30.156H88.4217C91.7325 30.156 93.8816 28.2973 93.8816 24.9091C93.8816 21.5015 91.7325 19.6428 88.4217 19.6428Z"
        fill="#B156EC"
      />
      <path
        d="M67.4194 31.6662L73.7312 18.1133H75.9771L82.3082 31.6662H80.1398L78.5715 28.1618H71.0594L69.4911 31.6662H67.4194ZM72.1436 25.7223L71.6983 26.729H77.9132L77.4679 25.7223L74.8154 19.7784L72.1436 25.7223Z"
        fill="#B156EC"
      />
      <path
        d="M68.3854 23.67H66.4299C66.0426 20.9787 64.0097 19.3911 61.3959 19.3911C58.3756 19.3911 56.2458 21.4434 56.2458 24.8897C56.2458 28.3554 58.3756 30.3883 61.3766 30.3883C64.0484 30.3883 66.062 28.762 66.4299 26.0127H68.3854C67.9207 29.7494 65.0746 31.9372 61.3379 31.9372C57.2526 31.9372 54.271 29.3428 54.271 24.8897C54.271 20.456 57.272 17.8422 61.3572 17.8422C65.0359 17.8422 67.9401 19.972 68.3854 23.67Z"
        fill="#B156EC"
      />
      <path
        d="M40.2876 31.6662L46.5994 18.1133H48.8453L55.1764 31.6662H53.008L51.4397 28.1618H43.9275L42.3593 31.6662H40.2876ZM45.0117 25.7223L44.5664 26.729H50.7814L50.3361 25.7223L47.6836 19.7784L45.0117 25.7223Z"
        fill="#B156EC"
      />
    </svg>
  )
}

export default Logotype
