import React, { useState, useEffect } from "react"
import { Box, Heading, Input, Button, Text } from "@chakra-ui/react"
import { auth } from "../firebase"
import { sendSignInLinkToEmail } from "firebase/auth"
import SEO from "../components/SEO"
import Logotype from "../components/Logotype"

const SignInPage: React.FC = () => {
  const [email, setEmail] = useState("")
  const [isClient, setIsClient] = useState(false)
  const [signInSuccess, setSignInSuccess] = useState(false)

  useEffect(() => {
    setIsClient(true)
    console.log("Client side rendering")
  }, [])

  useEffect(() => {
    if (signInSuccess && isClient) {
      try {
        window.localStorage.setItem("emailForSignIn", email)
        window.localStorage.setItem("darkMode", "false")
        console.log("LocalStorage set for emailForSignIn and darkMode")
      } catch (error) {
        console.error("Failed to set localStorage items:", error)
      }
    }
  }, [signInSuccess, isClient, email])

  const handleSignIn = async () => {
    try {
      const url = `${window.location.origin}/finishSignIn`
      const actionCodeSettings = {
        url,
        handleCodeInApp: true,
      }
      await sendSignInLinkToEmail(auth, email, actionCodeSettings)
      setSignInSuccess(true)
      console.log("Sign-in email sent successfully")
    } catch (error) {
      console.error("Error sending email:", error)
      alert(`Failed to send magic link to ${email}`)
    }
  }

  return (
    <>
      <SEO title="Sign In" />
      <div className="page">
        <div className="login-area">
          <div className="login-box">
            <div className="login-logo">
              <Logotype></Logotype>
            </div>
            <div className="form-area">
              <div className="form">
                <div className="titles">
                  <div className="title">
                    {signInSuccess ? "We've sent you your code" : "Login"}
                  </div>
                  <div className="sub-title">
                    {signInSuccess
                      ? "Please check your email for the login link and follow the instructions."
                      : "Enter your email to receive a login link"}
                  </div>
                </div>
                {!signInSuccess && (
                  <div className="input-area">
                    <div className="input">
                      <div className="input-title">Email</div>
                      <Input
                        placeholder="Enter your email"
                        className="email-field"
                        value={email}
                        onChange={(e: any) => setEmail(e.target.value)}
                        mb={6}
                      />
                    </div>
                    <Button className="button" onClick={handleSignIn}>
                      Send Magic Link
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <style>
          {`
          .page {
            background: var(--background-surface-background-variant, #F2F3F6);
            display: flex;
            padding: 16px;
            justify-content: center;
            align-items: center;
            gap: 24px;
            flex: 1 0 0;
            align-self: stretch;
            height: 100vh;
          }
          .login-area {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            flex: 1 0 0;
            max-width: 600px;
          }
          .login-box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
            border-radius: 8px;
            min-height: 85vh;
            background: var(--Elevation-Surface-2, #FFF);
          }
          .login-logo {
            display: flex;
            padding: 54px 24px 24px 24px;
            justify-content: center;
            align-items: flex-start;
            gap: 11.593px;
            align-self: stretch;
          }
          .logo {
            width: 195.612px;
            height: 32px;
          }
          .form-area {
            display: flex;
            padding: 0px 48px;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            align-self: stretch;
          }
          .form {
            display: flex;
            max-width: 480px;
            padding: 48px 0px;
            flex-direction: column;
            align-items: flex-start;
            gap: 48px;
            align-self: stretch;
          }
          .titles {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
          }
          .title {
            display: flex;
            padding: 32px 0px 8px 0px;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            color: var(--Text-Text-Primary, #1C1A22);
            font-family: Inter;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px; /* 114.286% */
          }
          .sub-title {
            color: var(--Text-Text-Secondary, #605D66);
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            letter-spacing: 0.1px;
            display: flex;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
          }
          .input-area {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
          }
          .input {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            color:black;
          }
          .input-title {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            color: var(--Text-Text-Primary, #1C1A22);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 128.571% */
            letter-spacing: 0.2px;
          }
          .email-field {
            border: 1px solid var(--background-surface-outline, #DEE0E5);
            border-radius: 4px;
            font-family: 'Inter', sans-serif;
            white-space: nowrap;
            letter-spacing: 0.2px;
            line-height: 1.5;
            width: 100%;
            line-height: 150%;
            justify-content: center;
            padding: 16px;
          }
          .button {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background-color: var(--Primary-Primary, #692fed);
            margin-top: 32px;
            color: var(--Primary-On-Primary, #fff);
            text-align: center;
            letter-spacing: 0.1px;
            padding: 14px 60px;
            font: 500 14px/1.43 Inter, -apple-system, Roboto, Helvetica, sans-serif;
            width: 100%;
          }
      `}
        </style>
      </div>
    </>
  )
}

export default SignInPage
