import React from "react"
import { AuthProvider } from "./src/context/authContext"

export const wrapRootElement = ({ element }) => (
  <AuthProvider>{element}</AuthProvider>
)

export const onClientEntry = () => {
  if (typeof window !== "undefined") {
    const darkMode = window.localStorage.getItem("darkMode");
    if (darkMode === null) {
      window.localStorage.setItem("darkMode", "false");
    }
  }
};

// Function to load SignInPage component only in the client-side
export const wrapPageElement = ({ element, props }) => {
  if (props.path === "/sign-in/") {
    const SignInPage = require("./src/pages/sign-in").default
    return <SignInPage {...props} />
  }
  return element
}